import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import SectionHeader from '../components/SectionHeader/SectionHeader'
import BottomNav from '../components/BottomNav/BottomNav'
import PrivacyPolicyUSA from '../components/PrivacyPolicy/PrivacyPolicyUSA'

const PrivacyPolicyUSAPage = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy USA"
        keywords={[
          `Emersoft LLC`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />

      <SectionHeader paragraph='Emersoft LLC'>Terms of Service and Privacy Policy</SectionHeader>

      <PrivacyPolicyUSA />

      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/services/websites"
        firstHeading="Websites/Design"
        secondLink="/services/support"
        secondHeading="Supporting Services"
      />
    </Layout>
  )
}

export default PrivacyPolicyUSAPage
